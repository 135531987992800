import styled from "styled-components";
import { breakpoints } from "../../config/breakpoints";

export const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background: #F2F6FF;
  // padding: 48px;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  background: linear-gradient(to bottom, #F8FBFD, #CEE7FB);
  gap: 16px;
  @media screen and (max-width: ${breakpoints.sm}) {
    // padding: 24px;
  }
`

export const Header = styled.div`
  display: flex;
  gap: 16px;
  z-index: 1000;
  @media screen and (max-width: ${breakpoints.sm}) {
    justify-content: center;
  }
`
export const HeaderTitle = styled.div`
  font-family: Outfit;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  // color: white;

  & span {
    color: #FD4560;
  }
`

export const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 28px;
  width: 100%;
  height: 100%;
`
export const MainContentMb = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;
`

export const LeftBlock = styled.div`
display: flex;
flex-direction: column;
gap: 18px;
padding: 8rem 6rem;
flex: 1;
`

export const RightBlock = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`

export const CTA = styled.div`
  margin-top: 16px;
  z-index: 99999999999;
  @media screen and (max-width: ${breakpoints.sm}) {
    justify-content: center;
    display: flex;
    padding: 1rem 0 2rem 0;
  }
`
export const Background = styled.img`
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  position: absolute;
}
`
export const BgBlock = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  max-height: 50%;
`
export const BackgroundMb = styled.img`
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
`
export const ImgClock = styled.img`
  position: absolute;
  bottom: 0;
  left: 174px;
  z-index: 1000;
  @media screen and (max-width: ${breakpoints.sm}) {
    bottom: -20px;
    left: -6px;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    bottom: -20px;
    left: 10px;
  }
}
`

export const LottieWrapper = styled.div`
  z-index: 600;
  width: 100%;
  background-color: transparent;
  position: relative;
`

export const ButtonCustom = styled.button`
  padding: 6px 6px 6px 36px;
  gap: 10px;
  border-radius: 50px;
  font-family: Outfit;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
  background: #FD4560;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 99999999999;
  &:hover {
    background: #fd4560c2;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    font-size: 16px;
  }
`

export const Desc = styled.div`
  font-family: Outfit;
  font-size: 22px;
  font-weight: 400;
  line-height: 27.72px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #969BAA;
  @media screen and (max-width: ${breakpoints.md}) {
    max-width: 100%;
    font-size: 24px;
  }
`

export const DescMb = styled.div`
  font-family: Outfit;
  font-size: 22px;
  font-weight: 500;
  line-height: 27.72px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #28303F;
`
export const PeopleNumber = styled.span`
  color: #FD4560;
`

export const Tittle = styled.div`
  color: #28303F;
  font-family: Poppins;
  font-size: 56px;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  & span {
    background: -webkit-linear-gradient(#F07B8C, #EA475E);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }

  @media screen and (max-width: ${breakpoints.ipadAir}) {
    font-size: 48px;
  }

  
`

export const Line = styled.div`
  width: 66px;
  height: 0px;
  gap: 0px;
  border: 4px solid #FD4560
  // opacity: 0px;
`

export const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 1.5rem 1rem;
`