import React, { useRef } from "react";
import { Background, BackgroundMb, BgBlock, ButtonCustom, CTA, Desc, DescMb, Header, HeaderTitle, ImgClock, LeftBlock, Line, LottieWrapper, MainContent, MainContentMb, PeopleNumber, RightBlock, TitleBlock, Tittle, Wrapper } from "./styles";
import icHeart from '../../assets/icons/ic-heart.svg';
import icOpenLink from '../../assets/icons/ic-open-link.svg';
import bg from '../../assets/images/bg.png';
import bgMobile from '../../assets/images/bg-mb.png';
import useScreenSize from "../../hooks/firebase/useScreenSize";
import { EventName, useFirebaseTracking } from "../../hooks/firebase/useFirebaseTracking";

const Home = () => {
  const { isMobile } = useScreenSize();

  const { onEventTracking } = useFirebaseTracking();

  const goToStore = () => {
    console.log('zooo');
    
    onEventTracking(EventName.LANDINGPAGE_CLICK_CTA)
    window.open('https://apps.apple.com/vn/app/pulsevista-heartbeat-monitor/id6618118303?l=vi', '_blank')
  }

  const mobileUI = () => {
    return (
      <MainContentMb>
        <TitleBlock>
          <Header>
            <img src={icHeart} width={52} height={52} alt="ic-heart"/>
            <HeaderTitle>Pulse<span>Vista</span></HeaderTitle>
          </Header>
          <DescMb>Join <PeopleNumber>1,098,006*</PeopleNumber> people and take control over your heart health</DescMb>
        </TitleBlock>
        <BgBlock><BackgroundMb src={bgMobile} alt="bg"/></BgBlock>
        <CTA>
          <ButtonCustom onClick={goToStore}>
            Try PulseVista
            <img src={icOpenLink} width={52} height={52} alt="ic-open-link"/>
          </ButtonCustom>
        </CTA>
      </MainContentMb>
    )
  }

  const mobileDesktop = () => {
    return (
      <MainContent>
        <LeftBlock>
          <Header>
            <img src={icHeart} width={52} height={52} alt="ic-heart"/>
            <HeaderTitle>Pulse<span>Vista</span></HeaderTitle>
          </Header>
          <Tittle>
            Start your journey <br />
            <span><Line /> to improved* </span>
            mental health now! <br />
          </Tittle>
          <Desc>Explore new technology for heart rate monitoring, healthy lifestyles like meditation, and tips for overall wellness</Desc>
          <CTA>
            <ButtonCustom onClick={goToStore}>
              Try PulseVista
              <img src={icOpenLink} width={52} height={52} alt="ic-open-link"/>
            </ButtonCustom>
          </CTA>
        </LeftBlock>
        <RightBlock>
          <Background src={bg} alt="bg"/>
        </RightBlock>
      </MainContent>
    )
  }
  return(
    <Wrapper>
      {isMobile ? mobileUI() : mobileDesktop()}
    </Wrapper>
  )
}

export default React.memo(Home);